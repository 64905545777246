import React from "react"
import "../../mystyles.scss"

export default function Resultat() {
  return (
    <div className="container">
      <h1 className="title">
        Résultats du 7 juin 2020 : Les 5km pour le summerbody
      </h1>
      <div className="columns">
        <div className="column">
          <h2 className="subtitle"> Parcours 1 (5km)</h2>
          <table class="table">
            <thead>
              <tr>
                <th>Nom et Prénom</th>
                <th>Temps</th>
                <th>Allure</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Marc-Antoine Levionnois</td>
                <td>19:54</td>
                <td>3'58/km</td>
              </tr>
              <tr>
                <td>Christophe Heckel</td>
                <td>21:34</td>
                <td>4'18/km</td>
              </tr>
              <tr>
                <td>Bao Luu</td>
                <td>23:45</td>
                <td>4'45/km</td>
              </tr>
              <tr>
                <td>Igor Francey</td>
                <td>24:10</td>
                <td>4'50/km</td>
              </tr>
              <tr>
                <td>Jonathen Vonlanthen</td>
                <td>26:00</td>
                <td>5'11/km</td>
              </tr>
              <tr>
                <td>Julie Beuret</td>
                <td>26:30</td>
                <td>5'17/km</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
